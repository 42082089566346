import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      @Copyright <b> <a href="https://www.idweb-dz.com/" target="_blank">IDWEB </a></b> 
    </span>
   
  `,
})
export class FooterComponent {
}
