import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthResponseData } from './AuthResponseData';
import { tap } from 'rxjs/operators';
import { User } from '../pages/models/user.module';
import { Login } from './login.model';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private apiServerUrl=environment.apiServerUrl;
  private tokenExpirationTimer: any;
  loginSubject = new BehaviorSubject<Login>(null);
  public isAuthenticated:boolean;
  constructor(private http: HttpClient , private _router: Router) { }

  login(credentials): Observable<any> {
    const encodedPassword = encodeURIComponent(credentials.mot_de_pass);
    
    return this.http.get<AuthResponseData>(this.apiServerUrl+'/Login/' + 
      'getUserAuth?identifiant='+credentials.identifiant+'&mot_de_pass='+encodedPassword,{observe: 'response'})
      .pipe(
        tap(resData => {
        const log = new Login (resData.body.identifiant, resData.body.idToken, new Date(+resData.body.expirationDate));
        this.loginSubject.next(log);
        console.log('isAuthenticated',this.isAuthenticated) ;
        localStorage.setItem('userData', JSON.stringify(log));
        const duration = new Date(+resData.body.expirationDate).getTime() - new Date().getTime(); 
        this.isAuthenticated=true;
        this.autoLogOut(duration); 
      }));
  }

  // register(user): Observable<any> {
  //   return this.http.post(AUTH_API + 'signup', {
  //     username: user.username,
  //     email: user.email,
  //     password: user.password
  //   }, httpOptions);
  // }

  logout() {
    //debugger;
    console.log('logout');
    this.loginSubject.next(null);
    localStorage.removeItem('userData');
    sessionStorage.removeItem('auth-token');
    sessionStorage.removeItem('auth-user');
    this.isAuthenticated=false;
    console.log('logout this.isAuthenticated',this.isAuthenticated);
    if (!this.tokenExpirationTimer) {
        clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
    this._router.navigate(['/auth']);
}


  autoLogOut(expitationDuration: number) {
   // debugger;
    console.log('auto logout');
    console.log('in log out',expitationDuration);
    this.tokenExpirationTimer = setTimeout(() => {
        this.logout();
    }, expitationDuration);
}

// autoLogin() {
//   console.log('autologin');
//   // debugger;
//   const userData: {
//       identifiant: string;
//       idToken: string;
//       expirationDate: string
//   } = JSON.parse(localStorage.getItem('userData'));
  
//   if (!userData) {
//       return;
//   }
//   const localLogin = new Login(userData.identifiant, userData.idToken,new Date(userData.expirationDate));

//   if (localLogin.token) {
//       // console.log('in auto login from ap comp');
//       this.loginSubject.next(localLogin);
//       var exp = new Date(userData.expirationDate).getTime();
//       var now = new Date().getTime();
//       const duration = new Date(userData.expirationDate).getTime() - new Date().getTime(); 
//       this.autoLogOut(duration); 
//   }
// }


}



